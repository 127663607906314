import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentLinkPage = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        // Function to parse query parameters from the URL
        const getQueryParams = () => {
            const searchParams = new URLSearchParams(location.search);
            return new URLSearchParams({
                name: searchParams.get('name'),
                phone: searchParams.get('phone'),
                email: searchParams.get('email'),
                amount: searchParams.get('amount'),
                // Add more params as needed
            }).toString();
        };

        const getSubmissionId = () => {
            const searchParams = new URLSearchParams(location.search);
            return searchParams.get('submission_id');
        };

        const fetchPaymentLink = async () => {
            const queryParams = getQueryParams();
            const submissionId = getSubmissionId();

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${submissionId}?${queryParams}`, {
                    method: 'GET',
                });
                const data = await response.json();

                if (data.data.link_url) {
                    window.location.href = 'https://' + data.data.link_url;  // Redirect to the link_url
                } else {
                    setLoading(false);  // Stop loading if link_url is missing
                    console.error('link_url not found in the response');
                }
            } catch (error) {
                setLoading(false);  // Stop loading if there's an error
                console.error('Failed to fetch payment link:', error);
            }
        };

        fetchPaymentLink();
    }, [location.search]);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>  // Loading indicator
            ) : (
                <p>Failed to load payment link.</p>
            )}
        </div>
    );
};

export default PaymentLinkPage;